import { AssetSimpleResponse, EAssetType } from 'api/core';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { PauseIcon, PlayIcon } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

interface VideoPreviewWithTrimProps {
  asset: AssetSimpleResponse;
  trimStart?: number;
  trimEnd?: number;
  style?: React.CSSProperties;
  className?: string;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  hideControls?: boolean;
  canManuallyPlay?: boolean;
  canSeek?: boolean;
  volume?: number;
}

export const VideoPreviewWithTrim = forwardRef(
  (
    {
      asset,
      trimStart = 0,
      trimEnd,
      style,
      className = 'w-full',
      autoPlay = false,
      loop = false,
      muted = false,
      hideControls = false,
      canManuallyPlay = true,
      canSeek = true,
      volume = 1,
    }: VideoPreviewWithTrimProps,
    ref
  ) => {
    const [videoDuration, setVideoDuration] = useState<number>(0);
    const [internalTime, setInternalTime] = useState<number>(0);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    // Expose methods that can be called from parent component
    useImperativeHandle(ref, () => ({
      play: () => {
        videoRef.current?.play();
      },
      pause: () => {
        videoRef.current?.pause();
      },
      setCurrentTime: (time: number) => {
        if (videoRef.current) {
          videoRef.current.currentTime = time;
        }
      },
    }));

    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      video.volume = volume;
    }, [volume, videoRef]);

    useEffect(() => {
      const video = videoRef.current;
      if (!video) return;

      const handleLoadedMetadata = () => {
        const duration = Math.ceil(video.duration * 10) / 10;
        setVideoDuration(duration);

        // Start playback if autoplay is enabled
        if (autoPlay) {
          video.currentTime = trimStart; // Ensure it starts at the trimmed start
          video.play();
          setIsPlaying(true);
        }
      };

      const handleTimeUpdate = () => {
        if (video.currentTime < trimStart) {
          video.currentTime = trimStart;
        }

        if (trimEnd !== undefined && video.currentTime > trimEnd) {
          if (loop) {
            // Loop back to trimStart if looping is enabled
            video.currentTime = trimStart;
            video.play();
          } else {
            // Pause and reset if looping is not enabled
            video.currentTime = trimStart;
            video.pause();
            setIsPlaying(false);
          }
        }
        setInternalTime(video.currentTime - trimStart);
      };

      const handlePlay = () => {
        if (
          video.currentTime < trimStart ||
          (trimEnd !== undefined && video.currentTime > trimEnd)
        ) {
          video.currentTime = trimStart;
        }
      };

      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.addEventListener('play', handlePlay);

      return () => {
        video.removeEventListener('loadedmetadata', handleLoadedMetadata);
        video.removeEventListener('timeupdate', handleTimeUpdate);
        video.removeEventListener('play', handlePlay);
      };
    }, [trimStart, trimEnd, loop, autoPlay]);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.currentTime = trimStart;
      }
      setInternalTime(0);
    }, [trimStart, trimEnd]);

    const handlePlayPause = () => {
      if (!canManuallyPlay) return;

      const video = videoRef.current;
      if (!video) return;

      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    };

    const formatTime = (time: number) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    if (asset.type !== EAssetType.Video) return null;

    return (
      <div className="flex flex-col">
        <video
          crossOrigin="anonymous"
          ref={videoRef}
          style={style}
          className={className}
          muted={muted}
          onClick={handlePlayPause}
        >
          <source src={asset.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        {!hideControls ? (
          <div className="flex items-center bottom-0 gap-2 w-full p-3 bg-black bg-opacity-75 z-50">
            {canManuallyPlay ? (
              <div onClick={handlePlayPause} className="flex-shrink-0">
                {isPlaying ? (
                  <PauseIcon fill="white" className="w-4 h-4 text-white" />
                ) : (
                  <PlayIcon fill="white" className="w-4 h-4 text-white" />
                )}
              </div>
            ) : null}

            <input
              type="range"
              min={0}
              max={trimEnd !== undefined ? trimEnd - trimStart : videoDuration}
              step="0.1"
              disabled={!canSeek}
              value={internalTime}
              onChange={(e) => {
                const newTime = parseFloat(e.target.value);
                setInternalTime(newTime);
                if (videoRef.current) {
                  videoRef.current.currentTime = trimStart + newTime;
                }
              }}
              className={twMerge(
                'w-full sm:w-auto flex-grow appearance-none h-1.5 bg-white bg-opacity-40 rounded-lg outline-none',
                canSeek && 'cursor-pointer'
              )}
            />

            <div className="flex-shrink-0 flex space-x-1 text-sm text-white">
              <span>{formatTime(internalTime)}</span>
              <span className="opacity-50">
                /{' '}
                {formatTime(
                  trimEnd !== undefined ? trimEnd - trimStart : videoDuration
                )}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
);

VideoPreviewWithTrim.displayName = 'VideoPreviewWithTrim';
