import {
  QueryKey,
  QueryObserverOptions,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  MediaSequenceCategoriesApi,
  MediaSequenceCategoryCreateRequest,
  MediaSequenceCategoryResponse,
  MediaSequenceCategoryResponsePagedData,
  MediaSequenceCategoryUpdateRequest,
} from './core';
import { MetaMappingsApiKeys } from './useMetaMappingsApi';

export const MediaSequenceCategoryApiKeys = {
  SEARCH: 'media-sequences-search',
  GET_MULTIPLE: 'media-sequences',
  GET_SINGLE: 'media-sequence',
};

const client = new MediaSequenceCategoriesApi(new CoreBaseConfiguration());

export const useSearchMediaSequenceCategories = ({
  searchTerm,
}: {
  searchTerm?: string;
}) => {
  return useQuery({
    queryKey: [MediaSequenceCategoryApiKeys.SEARCH, searchTerm],
    queryFn: () =>
      client.mediaOrbitMediaSequenceCategoriesSearchGet({
        size: 10,
        searchTerm,
      }),
    enabled: searchTerm !== undefined || searchTerm != '',
  });
};

export const useGetMediaSequenceCategories = (
  state?: TableQueryState,
  options: Omit<
    QueryObserverOptions<
      MediaSequenceCategoryResponsePagedData,
      Error,
      MediaSequenceCategoryResponsePagedData,
      MediaSequenceCategoryResponsePagedData,
      QueryKey
    >,
    'queryKey'
  > = {}
) => {
  return useQuery<MediaSequenceCategoryResponsePagedData, Error>({
    queryKey: [MediaSequenceCategoryApiKeys.GET_MULTIPLE, state, options],
    queryFn: () =>
      client.mediaOrbitMediaSequenceCategoriesGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
    ...options,
  });
};

export const useGetMediaSequenceCategory = (
  id?: string,
  options: Omit<
    QueryObserverOptions<
      MediaSequenceCategoryResponse,
      Error,
      MediaSequenceCategoryResponse,
      MediaSequenceCategoryResponse,
      QueryKey
    >,
    'queryKey'
  > = {}
) => {
  return useQuery<MediaSequenceCategoryResponse, Error>({
    queryKey: [MediaSequenceCategoryApiKeys.GET_SINGLE, id],
    queryFn: async () => {
      const data = await client.mediaOrbitMediaSequenceCategoriesIdGet({
        id: id ?? '',
      });
      return data;
    },
    enabled: id !== undefined,
    ...options,
  });
};

export const useCreateMediaSequenceCategory = () => {
  return useMutation({
    mutationFn: ({
      mediaSequenceCategoryCreateRequest,
    }: {
      mediaSequenceCategoryCreateRequest: MediaSequenceCategoryCreateRequest;
    }) =>
      client.mediaOrbitMediaSequenceCategoriesPost({
        mediaSequenceCategoryCreateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceCategoryApiKeys.GET_MULTIPLE],
      });
      toast.success(`Kategorien '${e.name}' er blevet oprettet`, {
        toastId: 'createMediaSequenceCategory',
      });
    },
  });
};

export const useUpdateMediaSequenceCategory = () => {
  return useMutation({
    mutationFn: ({
      id,
      mediaSequenceCategoryUpdateRequest,
    }: {
      id: string;
      mediaSequenceCategoryUpdateRequest: MediaSequenceCategoryUpdateRequest;
    }) =>
      client.mediaOrbitMediaSequenceCategoriesIdPut({
        id,
        mediaSequenceCategoryUpdateRequest,
      }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceCategoryApiKeys.GET_MULTIPLE],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceCategoryApiKeys.GET_SINGLE, e.id],
      });
      queryClient.invalidateQueries({
        queryKey: [MetaMappingsApiKeys.GET_META_MAPPINGS, e.id],
      });
      toast.success(`Kategorien '${e.name}' er blevet opdateret`, {
        toastId: 'updateMediaSequenceCategory',
      });
    },
  });
};

export const useDeleteMediaSequenceCategory = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      client.mediaOrbitMediaSequenceCategoriesIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceCategoryApiKeys.GET_MULTIPLE],
      });
      queryClient.invalidateQueries({
        queryKey: [MediaSequenceCategoryApiKeys.GET_SINGLE, e.id],
      });
      toast.success(`Redigeringen '${e.name}' er blevet arkiveret`, {
        toastId: 'deleteMediaSequence',
      });
    },
  });
};
