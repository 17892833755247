/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ProjectCreateRequest,
  ProjectResponse,
  ProjectResponsePagedData,
  ProjectTranslationsResponse,
  ProjectTranslationsUpdateRequest,
  ProjectUpdateRequest,
} from '../models';
import {
    ProjectCreateRequestFromJSON,
    ProjectCreateRequestToJSON,
    ProjectResponseFromJSON,
    ProjectResponseToJSON,
    ProjectResponsePagedDataFromJSON,
    ProjectResponsePagedDataToJSON,
    ProjectTranslationsResponseFromJSON,
    ProjectTranslationsResponseToJSON,
    ProjectTranslationsUpdateRequestFromJSON,
    ProjectTranslationsUpdateRequestToJSON,
    ProjectUpdateRequestFromJSON,
    ProjectUpdateRequestToJSON,
} from '../models';

export interface ProjectsGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface ProjectsIdDeleteRequest {
    id: string;
}

export interface ProjectsIdGetRequest {
    id: string;
}

export interface ProjectsIdPutRequest {
    id: string;
    projectUpdateRequest?: ProjectUpdateRequest;
}

export interface ProjectsLastViewedGetRequest {
    amount?: number;
}

export interface ProjectsPostRequest {
    projectCreateRequest?: ProjectCreateRequest;
}

export interface ProjectsTranslationsIdGetRequest {
    id: string;
}

export interface ProjectsTranslationsIdPutRequest {
    id: string;
    projectTranslationsUpdateRequest?: ProjectTranslationsUpdateRequest;
}

/**
 * 
 */
export class ProjectsApi extends runtime.BaseAPI {

    /**
     */
    async projectsGetRaw(requestParameters: ProjectsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async projectsGet(requestParameters: ProjectsGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponsePagedData> {
        const response = await this.projectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectsIdDeleteRaw(requestParameters: ProjectsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async projectsIdDelete(requestParameters: ProjectsIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.projectsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectsIdGetRaw(requestParameters: ProjectsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async projectsIdGet(requestParameters: ProjectsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.projectsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectsIdPutRaw(requestParameters: ProjectsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateRequestToJSON(requestParameters.projectUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async projectsIdPut(requestParameters: ProjectsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.projectsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectsLastViewedGetRaw(requestParameters: ProjectsLastViewedGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects/last-viewed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectResponseFromJSON));
    }

    /**
     */
    async projectsLastViewedGet(requestParameters: ProjectsLastViewedGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectResponse>> {
        const response = await this.projectsLastViewedGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectsPostRaw(requestParameters: ProjectsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCreateRequestToJSON(requestParameters.projectCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectResponseFromJSON(jsonValue));
    }

    /**
     */
    async projectsPost(requestParameters: ProjectsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectResponse> {
        const response = await this.projectsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectsTranslationsIdGetRaw(requestParameters: ProjectsTranslationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTranslationsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsTranslationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects/Translations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTranslationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async projectsTranslationsIdGet(requestParameters: ProjectsTranslationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTranslationsResponse> {
        const response = await this.projectsTranslationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async projectsTranslationsIdPutRaw(requestParameters: ProjectsTranslationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectTranslationsResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling projectsTranslationsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/Projects/Translations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectTranslationsUpdateRequestToJSON(requestParameters.projectTranslationsUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectTranslationsResponseFromJSON(jsonValue));
    }

    /**
     */
    async projectsTranslationsIdPut(requestParameters: ProjectsTranslationsIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectTranslationsResponse> {
        const response = await this.projectsTranslationsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
