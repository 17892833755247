import { useMutation, useQuery } from '@tanstack/react-query';

import { TableQueryState } from 'components/Table/useTableQueryState';
import { queryClient } from 'query-client';
import { toast } from 'react-toastify';
import { CoreBaseConfiguration } from './BaseConfiguration';
import {
  ProjectCreateRequest,
  ProjectsApi,
  ProjectTranslationsUpdateRequest,
  ProjectUpdateRequest,
} from './core';

export const ProjectApiKeys = {
  GET_PROJECTS_LAST_VIEWED: 'projects-last-viewed',
  GET_PROJECTS: 'projects',
  GET_PROJECT: 'project',
  GET_PROJECT_TRANSLATIONS: 'project-translations',
};

const client = new ProjectsApi(new CoreBaseConfiguration());

export const useGetProjects = (state?: TableQueryState) => {
  return useQuery({
    queryKey: [ProjectApiKeys.GET_PROJECTS, state],
    queryFn: () =>
      client.projectsGet({
        pageNumber: state?.pagination.pageIndex,
        size: state?.pagination.pageSize,
        orderByProperty: state?.sorting[0]?.id,
        ascending: state?.sorting[0]?.desc === false,
        searchTerm: state?.globalFilter,
      }),
  });
};

export const useGetLastViewedProjects = (amount: number) => {
  return useQuery({
    queryKey: [ProjectApiKeys.GET_PROJECTS_LAST_VIEWED],
    queryFn: () =>
      client.projectsLastViewedGet({
        amount,
      }),
  });
};

export const useGetProject = (id?: string, invalidateLastViewed?: boolean) => {
  return useQuery({
    queryKey: [ProjectApiKeys.GET_PROJECT, id],
    queryFn: async () => {
      const data = await client.projectsIdGet({ id: id ?? '' });
      if (invalidateLastViewed) {
        queryClient.invalidateQueries({
          queryKey: [ProjectApiKeys.GET_PROJECTS_LAST_VIEWED],
        });
      }
      return data;
    },
    enabled: id !== undefined,
  });
};

export const useGetProjectTranslations = (id?: string) => {
  return useQuery({
    queryKey: [ProjectApiKeys.GET_PROJECT_TRANSLATIONS, id],
    queryFn: () =>
      client.projectsTranslationsIdGet({
        id: id ?? '',
      }),
    enabled: id !== undefined,
  });
};

export const useUpdateProjectTranslations = () => {
  return useMutation({
    mutationFn: ({
      id,
      projectTranslationsUpdateRequest,
    }: {
      id: string;
      projectTranslationsUpdateRequest: ProjectTranslationsUpdateRequest;
    }) =>
      client.projectsTranslationsIdPut({
        id,
        projectTranslationsUpdateRequest,
      }),
    onSuccess: (e, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECT_TRANSLATIONS, variables.id],
      });
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECTS],
      });
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECT, variables.id],
      });
      toast.success(`Oversættelser til projektet er blevet opdateret`, {
        toastId: 'updateProject',
      });
    },
  });
};

export const useCreateProject = () => {
  return useMutation({
    mutationFn: ({
      projectCreateRequest,
    }: {
      projectCreateRequest: ProjectCreateRequest;
    }) => client.projectsPost({ projectCreateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECTS_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECTS],
      });
      toast.success(`Projektet '${e.name}' er blevet oprettet`, {
        toastId: 'createProject',
      });
    },
  });
};

export const useUpdateProject = () => {
  return useMutation({
    mutationFn: ({
      id,
      projectUpdateRequest,
    }: {
      id: string;
      projectUpdateRequest: ProjectUpdateRequest;
    }) => client.projectsIdPut({ id, projectUpdateRequest }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECTS_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECTS],
      });
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECT, e.id],
      });
      toast.success(`Projektet '${e.name}' er blevet opdateret`, {
        toastId: 'updateProject',
      });
    },
  });
};

export const useDeleteProject = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => client.projectsIdDelete({ id }),
    onSuccess: (e) => {
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECTS_LAST_VIEWED],
      });
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECTS],
      });
      queryClient.invalidateQueries({
        queryKey: [ProjectApiKeys.GET_PROJECT, e.id],
      });
      toast.success(`Projektet '${e.name}' er blevet slettet`, {
        toastId: 'deleteProject',
      });
    },
  });
};
