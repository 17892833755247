/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectLanguageTranslationsResponse } from './ProjectLanguageTranslationsResponse';
import {
    ProjectLanguageTranslationsResponseFromJSON,
    ProjectLanguageTranslationsResponseFromJSONTyped,
    ProjectLanguageTranslationsResponseToJSON,
} from './ProjectLanguageTranslationsResponse';

/**
 * 
 * @export
 * @interface ProjectTranslationsResponse
 */
export interface ProjectTranslationsResponse {
    /**
     * 
     * @type {Array<ProjectLanguageTranslationsResponse>}
     * @memberof ProjectTranslationsResponse
     */
    languageTranslations: Array<ProjectLanguageTranslationsResponse>;
}

/**
 * Check if a given object implements the ProjectTranslationsResponse interface.
 */
export function instanceOfProjectTranslationsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "languageTranslations" in value;

    return isInstance;
}

export function ProjectTranslationsResponseFromJSON(json: any): ProjectTranslationsResponse {
    return ProjectTranslationsResponseFromJSONTyped(json, false);
}

export function ProjectTranslationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTranslationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'languageTranslations': ((json['languageTranslations'] as Array<any>).map(ProjectLanguageTranslationsResponseFromJSON)),
    };
}

export function ProjectTranslationsResponseToJSON(value?: ProjectTranslationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'languageTranslations': ((value.languageTranslations as Array<any>).map(ProjectLanguageTranslationsResponseToJSON)),
    };
}

