import { AssetMetadataWithValue, EAssetMetadata } from 'api/core';
import {
  getDocument,
  GlobalWorkerOptions,
  version as PdfJsVersion,
} from 'pdfjs-dist';

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PdfJsVersion}/pdf.worker.min.mjs`;

export const calculateChecksumAsync = async (file: File | Blob) => {
  const buffer = await file.arrayBuffer();
  const digest = await crypto.subtle.digest('SHA-256', buffer);
  return hexString(digest);
};

export const getMetadataForVideoAsync = (
  file: File
): Promise<AssetMetadataWithValue[]> => {
  return new Promise((resolve, reject) => {
    if (!file.type.startsWith('video/')) {
      reject('Invalid file type');
      return;
    }

    const video = document.createElement('video');
    const url = URL.createObjectURL(file);

    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      resolve([
        {
          key: EAssetMetadata.MediaDuration,
          value: video.duration.toString(),
        },
        {
          key: EAssetMetadata.Width,
          value: video.videoWidth.toString(),
        },
        {
          key: EAssetMetadata.Height,
          value: video.videoHeight.toString(),
        },
      ]);
      video.remove();
    };

    video.onerror = (error) => {
      URL.revokeObjectURL(url);
      video.remove();
      reject('Error loading video metadata: ' + error);
    };

    video.src = url;
  });
};

export const getMetadataForImageAsync = (
  file: File
): Promise<AssetMetadataWithValue[]> => {
  return new Promise((resolve, reject) => {
    if (!file.type.startsWith('image/')) {
      reject('Invalid file type');
      return;
    }

    const image = new Image();
    const url = URL.createObjectURL(file);

    image.onload = () => {
      URL.revokeObjectURL(url);
      resolve([
        {
          key: EAssetMetadata.Width,
          value: image.width.toString(),
        },
        {
          key: EAssetMetadata.Height,
          value: image.height.toString(),
        },
      ]);
      image.remove();
    };

    image.onerror = (error) => {
      URL.revokeObjectURL(url);
      image.remove();
      reject('Error loading image: ' + error);
    };

    image.src = url;
  });
};

export const getMetadataForAudioAsync = (
  file: File
): Promise<AssetMetadataWithValue[]> => {
  return new Promise((resolve, reject) => {
    if (!file.type.startsWith('audio/')) {
      reject('Invalid file type');
      return;
    }

    const audio = new Audio();
    const url = URL.createObjectURL(file);

    audio.preload = 'metadata';

    audio.onloadedmetadata = () => {
      URL.revokeObjectURL(url);
      resolve([
        {
          key: EAssetMetadata.MediaDuration,
          value: audio.duration.toString(),
        },
      ]);
      audio.remove();
    };

    audio.onerror = (error) => {
      URL.revokeObjectURL(url);
      audio.remove();
      reject('Error loading audio metadata: ' + error);
    };

    audio.src = url;
  });
};

export const getBlobFromUrlAsync = (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      mode: 'cors',
      credentials: 'omit',
    })
      .then((response) => {
        if (!response.ok) {
          reject('Failed to fetch the resource');
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        if (!blob) {
          reject('Failed to fetch the resource');
          return;
        }
        resolve(blob);
      })
      .catch((error) => {
        reject('Error fetching the resource: ' + error.message);
      });
  });
};

export const getAudioDurationAsync = (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    getBlobFromUrlAsync(url)
      .then((blob) => {
        const audio = new Audio();
        audio.src = URL.createObjectURL(blob);
        audio.preload = 'metadata';
        audio.muted = true;

        const handleDuration = () => {
          // If the duration is still infinity after seeking, reject
          if (audio.duration === Infinity) {
            reject('Audio duration is still infinity after seeking');
          } else {
            resolve(audio.duration);
          }
          URL.revokeObjectURL(audio.src); // Cleanup the object URL
        };

        audio.addEventListener('loadedmetadata', () => {
          if (audio.duration === Infinity) {
            // Seek to the end to get the real duration
            audio.currentTime = Number.MAX_SAFE_INTEGER;
            audio.addEventListener('timeupdate', handleDuration, {
              once: true,
            });
          } else {
            resolve(audio.duration);
          }
        });

        audio.addEventListener('error', (error) => {
          reject('Error loading audio metadata: ' + error);
        });
      })
      .catch((error) => {
        reject('Error fetching audio: ' + error);
      });
  });
};

export const getVideoDurationAsync = async (url: string): Promise<number> => {
  try {
    // Fetch the video as a blob
    const blob = await getBlobFromUrlAsync(url);

    // Wrap the blob as a File object
    const file = new File([blob], 'video.mp4', { type: 'video/mp4' });

    // Get metadata for the video
    const metadata = await getMetadataForVideoAsync(file);

    // Extract the duration from the metadata
    const durationMetadata = metadata.find(
      (e) => e.key === EAssetMetadata.MediaDuration
    );

    if (!durationMetadata) {
      throw new Error('Duration metadata not found');
    }

    if (!durationMetadata.value) {
      throw new Error('Duration value not found');
    }

    // Parse the duration as a float
    return parseFloat(durationMetadata.value);
  } catch (error) {
    console.error('Error while calculating video duration', error);
    throw error;
  }
};

const hexString = (buffer: ArrayBuffer) => {
  const byteArray = new Uint8Array(buffer);
  const hexCodes = [...byteArray].map((value) => {
    return value.toString(16).padStart(2, '0');
  });
  return hexCodes.join('');
};

export const clearWaveform = (canvas: HTMLCanvasElement) => {
  if (!canvas) return;
  const canvasCtx = canvas.getContext('2d');
  if (!canvasCtx) return;
  const { width, height } = canvas;
  canvasCtx.clearRect(0, 0, width, height);
};

export const drawWaveformAsync = async (
  audioBlob: Blob,
  canvas: HTMLCanvasElement
) => {
  const audioContext = new AudioContext();
  const arrayBuffer = await audioBlob.arrayBuffer();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  if (!canvas) return;
  const canvasCtx = canvas.getContext('2d');
  if (!canvasCtx) return;

  const { width, height } = canvas;
  canvasCtx.clearRect(0, 0, width, height);

  const bufferLength = audioBuffer.length;
  const dataArray = audioBuffer.getChannelData(0);

  canvasCtx.fillStyle = 'rgb(200, 200, 200)';
  canvasCtx.fillRect(0, 0, width, height);

  canvasCtx.lineWidth = 2;
  canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

  canvasCtx.beginPath();

  const sliceWidth = (width * 1.0) / bufferLength;
  let x = 0;

  for (let i = 0; i < bufferLength; i++) {
    const v = dataArray[i] * 0.5;
    const y = height / 2 + v * height;

    if (i === 0) {
      canvasCtx.moveTo(x, y);
    } else {
      canvasCtx.lineTo(x, y);
    }

    x += sliceWidth;
  }

  canvasCtx.lineTo(canvas.width, canvas.height / 2);
  canvasCtx.stroke();
};

export const getDrawRealTimeWaveformFn = (
  stream: MediaStream,
  canvasRef: React.RefObject<HTMLCanvasElement>
) => {
  const audioContext = new AudioContext();
  const source = audioContext.createMediaStreamSource(stream);
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 2048;
  source.connect(analyser);

  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  const drawRealTimeWaveform = () => {
    analyser.getByteTimeDomainData(dataArray);

    const canvas = canvasRef.current;

    if (!canvas) return;
    const canvasCtx = canvas.getContext('2d');
    if (!canvasCtx) return;

    const { width, height } = canvas;
    canvasCtx.clearRect(0, 0, width, height);

    canvasCtx.fillStyle = 'rgb(200, 200, 200)';
    canvasCtx.fillRect(0, 0, width, height);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

    canvasCtx.beginPath();

    const sliceWidth = (width * 1.0) / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0;
      const y = (v * height) / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(canvas.width, canvas.height / 2);
    canvasCtx.stroke();
  };

  return drawRealTimeWaveform;
};

export const convertPdfToImageAsync = (pdf: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      if (!fileReader.result) return;
      if (typeof fileReader.result === 'string') return;
      const pdfData = new Uint8Array(fileReader.result);

      try {
        // Load the PDF
        const pdf = await getDocument({ data: pdfData }).promise;

        // Render the first page
        const page = await pdf.getPage(1);

        // Define the viewport
        const viewport = page.getViewport({ scale: 2 }); // Adjust scale for higher resolution
        const canvas = document.createElement('canvas');

        const context = canvas.getContext('2d');
        if (!context) throw new Error('Canvas 2D context not found');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport,
        };

        await page.render(renderContext).promise;

        // Convert the canvas to an image source
        const imageData = canvas.toDataURL('image/png');
        const blob = await fetch(imageData).then((res) => res.blob());
        resolve(blob);
      } catch (error) {
        reject(error);
      }
    };

    fileReader.readAsArrayBuffer(pdf);
  });
};
