/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProjectTranslationUpdateRequest } from './ProjectTranslationUpdateRequest';
import {
    ProjectTranslationUpdateRequestFromJSON,
    ProjectTranslationUpdateRequestFromJSONTyped,
    ProjectTranslationUpdateRequestToJSON,
} from './ProjectTranslationUpdateRequest';

/**
 * 
 * @export
 * @interface ProjectTranslationsUpdateRequest
 */
export interface ProjectTranslationsUpdateRequest {
    /**
     * 
     * @type {Array<ProjectTranslationUpdateRequest>}
     * @memberof ProjectTranslationsUpdateRequest
     */
    translations: Array<ProjectTranslationUpdateRequest>;
}

/**
 * Check if a given object implements the ProjectTranslationsUpdateRequest interface.
 */
export function instanceOfProjectTranslationsUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "translations" in value;

    return isInstance;
}

export function ProjectTranslationsUpdateRequestFromJSON(json: any): ProjectTranslationsUpdateRequest {
    return ProjectTranslationsUpdateRequestFromJSONTyped(json, false);
}

export function ProjectTranslationsUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectTranslationsUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'translations': ((json['translations'] as Array<any>).map(ProjectTranslationUpdateRequestFromJSON)),
    };
}

export function ProjectTranslationsUpdateRequestToJSON(value?: ProjectTranslationsUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'translations': ((value.translations as Array<any>).map(ProjectTranslationUpdateRequestToJSON)),
    };
}

