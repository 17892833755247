/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LanguageResponse } from './LanguageResponse';
import {
    LanguageResponseFromJSON,
    LanguageResponseFromJSONTyped,
    LanguageResponseToJSON,
} from './LanguageResponse';
import type { TranslationTermResponse } from './TranslationTermResponse';
import {
    TranslationTermResponseFromJSON,
    TranslationTermResponseFromJSONTyped,
    TranslationTermResponseToJSON,
} from './TranslationTermResponse';

/**
 * 
 * @export
 * @interface ProjectLanguageTranslationsResponse
 */
export interface ProjectLanguageTranslationsResponse {
    /**
     * 
     * @type {LanguageResponse}
     * @memberof ProjectLanguageTranslationsResponse
     */
    language: LanguageResponse;
    /**
     * 
     * @type {Array<TranslationTermResponse>}
     * @memberof ProjectLanguageTranslationsResponse
     */
    translations: Array<TranslationTermResponse>;
}

/**
 * Check if a given object implements the ProjectLanguageTranslationsResponse interface.
 */
export function instanceOfProjectLanguageTranslationsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "translations" in value;

    return isInstance;
}

export function ProjectLanguageTranslationsResponseFromJSON(json: any): ProjectLanguageTranslationsResponse {
    return ProjectLanguageTranslationsResponseFromJSONTyped(json, false);
}

export function ProjectLanguageTranslationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectLanguageTranslationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': LanguageResponseFromJSON(json['language']),
        'translations': ((json['translations'] as Array<any>).map(TranslationTermResponseFromJSON)),
    };
}

export function ProjectLanguageTranslationsResponseToJSON(value?: ProjectLanguageTranslationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': LanguageResponseToJSON(value.language),
        'translations': ((value.translations as Array<any>).map(TranslationTermResponseToJSON)),
    };
}

