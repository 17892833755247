import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useGetMediaSequenceCategories } from 'api/useMediaSequenceCategoriesApi';
import { MediaSequenceCategoriesTable } from './components/MediaSequenceCategory/MediaSequenceCategoriesTable';
import { MediaSequenceCategoryCreate } from './components/MediaSequenceCategory/MediaSequenceCategoryCreate';

export const MediaSequenceCategoriesView = () => {
  const { state } = useTableQueryState();
  const { data, isFetching } = useGetMediaSequenceCategories(state);

  return (
    <Page
      title={'Smart Video kategorier'}
      breadcrumbs={[{ name: 'Smart Video kategorier' }]}
    >
      <MediaSequenceCategoryCreate
        trigger={
          <div className="flex justify-start py-4 w-full">
            <button className="btn btn-primary mr-2">
              Opret Smart Video kategori
            </button>
          </div>
        }
      />
      <MediaSequenceCategoriesTable
        data={data}
        isFetching={isFetching}
        queryState={state}
      />
    </Page>
  );
};
