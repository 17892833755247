import {
  EMediaSequenceState,
  EMediaSequenceType,
  EPermission,
  ETranscriptionState,
  MediaSequenceResponse,
} from 'api/core';
import { PageAlert } from 'layouts/Admin/Page';
import { ReactNode } from 'react';
import { MediaSequenceSetupAssets } from './MediaSequenceSetupAssets';
import { MediaSequenceSetupBranchMutations } from './MediaSequenceSetupBranchMutations';
import { MediaSequenceSetupBranches } from './MediaSequenceSetupBranches';
import { MediaSequenceSetupResult } from './MediaSequenceSetupResult';
import { MediaSequenceSetupTranscription } from './MediaSequenceSetupTranscription';
import { Step } from 'react-joyride';
import { MediaSequenceSetupError } from './MediaSequenceSetupError';
import { MediaSequenceSetupSound } from './MediaSequenceSetupSound';
import { MediaSequenceSetupTemplate } from './MediaSequenceSetupTemplate';
import { isTemplate } from 'pages/MediaOrbit/media-sequence-utils';

export interface MediaSequenceSetupStep {
  id: string;
  title: string;
  pageAlert?: PageAlert;
  component: ReactNode;
  disablePrevious?: boolean;
  disableNext?: boolean;
  permissions: EPermission[];
}

export const getSetupSteps = (
  mediaSequence?: MediaSequenceResponse
): MediaSequenceSetupStep[] => {
  if (!mediaSequence) return [];

  const disabled = mediaSequence?.state !== EMediaSequenceState.Draft;

  const result: MediaSequenceSetupStep[] = [
    ...(mediaSequence.showTemplateStep
      ? [
          {
            id: 'template',
            title: 'Skabelon',
            pageAlert: {
              header: 'Opsætning af skabelon',
              message:
                'Her skal du udfylde værdier som er påkrævet i skabelonen.',
            },
            component: (
              <MediaSequenceSetupTemplate
                mediaSequence={mediaSequence}
                disabled={disabled}
              />
            ),
            permissions: [EPermission.MediaSequenceEditorAssets],
          } as MediaSequenceSetupStep,
        ]
      : []),
    {
      id: 'assets',
      title: 'Filer',
      pageAlert: {
        header: 'Opsætning af videofiler',
        message:
          'Her kan du tilføje billeder og videoklip, tildele dem effekter og ændre rækkefølgen i din video.',
      },
      component: (
        <MediaSequenceSetupAssets
          mediaSequence={mediaSequence}
          disabled={disabled}
        />
      ),
      permissions: [EPermission.MediaSequenceEditorAssets],
    },
    {
      id: 'branches',
      title: 'Formater',
      pageAlert: {
        header: 'Opsætning af videoformater',
        message:
          'Vælg de formater som din video skal udgives i. Smart Video vil automatisk tilpasse videoen.',
      },
      component: (
        <MediaSequenceSetupBranches
          mediaSequence={mediaSequence}
          disabled={disabled}
        />
      ),
      permissions: [EPermission.MediaSequenceEditorBranchMutations],
    },
    {
      id: 'sounds',
      title: 'Baggrundslyd',
      pageAlert: {
        header: 'Opsætning af baggrundslyd',
        message:
          'Vælg den baggrundslyd der skal bruges i din video. Du kan også uploade din egen baggrundslyd.',
      },
      component: (
        <MediaSequenceSetupSound
          mediaSequence={mediaSequence}
          disabled={disabled}
        />
      ),
      permissions: [EPermission.MediaSequenceEditorBranchMutations],
    },
    ...(mediaSequence.type !== EMediaSequenceType.Strict
      ? [
          {
            id: 'branch-mutations',
            title: 'Videoeffekter',
            pageAlert: {
              header: 'Opsætning af videoeffekter',
              message:
                'Her kan du tilføje effekter til din video. Effekterne vil blive tilføjet i hele videoen.',
            },
            component: (
              <MediaSequenceSetupBranchMutations
                mediaSequence={mediaSequence}
                disabled={disabled}
              />
            ),
            permissions: [EPermission.MediaSequenceEditorBranchMutations],
          },
        ]
      : []),
    ...(mediaSequence.showTranscriptionsStep && !isTemplate(mediaSequence)
      ? [
          {
            id: 'subtitles',
            title: 'Undertekster',
            pageAlert: {
              header: 'Opsætning af undertekster',
              message:
                'Her kan du godkende og rette underteskterne i din video.',
            },
            component: (
              <MediaSequenceSetupTranscription
                mediaSequence={mediaSequence}
                disabled={
                  mediaSequence.transcriptionState ===
                  ETranscriptionState.Approved
                }
              />
            ),
            permissions: [EPermission.MediaSequenceEditorTranscription],
          } as MediaSequenceSetupStep,
        ]
      : []),
    ...(mediaSequence.state === EMediaSequenceState.Failed
      ? [
          {
            id: 'error',
            title: 'Fejl',
            pageAlert: {
              header: 'Smart Video er fejlet',
              message:
                'Der opstod desværre en uventet fejl under behandlingen af din video.',
              type: 'error',
            },
            component: (
              <MediaSequenceSetupError mediaSequence={mediaSequence} />
            ),
            permissions: [EPermission.MediaSequenceRead],
          } as MediaSequenceSetupStep,
        ]
      : []),
    ...(!isTemplate(mediaSequence)
      ? [
          {
            id: 'results',
            title: 'Resultat',
            pageAlert: {
              header: 'Resultat af Smart Video',
              message:
                mediaSequence.branches.length === 1
                  ? `I dette trin kan du se resultatet for det valgte format.`
                  : `I dette trin kan du se resultaterne for dine ${mediaSequence.branches.length} formater.`,
            },
            component: (
              <MediaSequenceSetupResult
                mediaSequence={mediaSequence}
                disabled={disabled}
              />
            ),
            permissions: [EPermission.MediaSequenceEditorResults],
          },
        ]
      : []),
    // {
    //   id: 'publish',
    //   title: 'Publicering',
    //   pageAlert: {
    //     header: 'Publicering af Smart Video',
    //     message: `I dette trin kan du publicere resultatet til dine ${mediaSequence.branches.length} formater på sociale medier.`,
    //   },
    //   component: (
    //     <MediaSequenceSetupPublish
    //       mediaSequence={mediaSequence}
    //       disabled={disabled}
    //     />
    //   ),
    //   permissions: [EPermission.ModuleSocialMedia],
    // },
  ];

  return result;
};

export const getCurrentProgressStepIndex = (
  mediaSequence: MediaSequenceResponse,
  steps: MediaSequenceSetupStep[],
  isAddDynamicOverlaysValid: boolean,
  hasPublishPermission: boolean
): number => {
  // If state is failed go to the error step
  if (mediaSequence.state === EMediaSequenceState.Failed) {
    const errorStep = steps.find((step) => step.id === 'error');
    if (errorStep) {
      return steps.indexOf(errorStep) + 1;
    }
  }

  // If it is processed and used has permissions to publish, go to the publish step
  if (
    mediaSequence.state === EMediaSequenceState.Processed &&
    hasPublishPermission
  ) {
    const publishStep = steps.find((step) => step.id === 'publish');
    if (publishStep) {
      return steps.indexOf(publishStep) + 1;
    }
  }

  // If any branch has a finished asset, go to the results step
  const anyBranchFinished = mediaSequence.branches.some((e) => e.asset);
  if (anyBranchFinished) {
    const resultState = steps.find((step) => step.id === 'results');
    if (resultState) {
      return steps.indexOf(resultState) + 1;
    }
  }

  // If subtitles are generated, go to that step
  if (
    mediaSequence.transcriptionState === ETranscriptionState.Generated ||
    mediaSequence.transcriptionState === ETranscriptionState.Pending
  ) {
    const transcriptionStep = steps.find((step) => step.id === 'subtitles');
    if (transcriptionStep) {
      return steps.indexOf(transcriptionStep) + 1;
    }
  }

  // If all dynamic overlay mutations are valid, go to next step
  if (isAddDynamicOverlaysValid) {
    const templateStep = steps.find((step) => step.id === 'template');
    if (templateStep) {
      return steps.indexOf(templateStep) + 2;
    }
  }

  // If it is a draft, go to the first step
  if (mediaSequence.state === EMediaSequenceState.Draft) {
    return 1;
  }

  // Else go to the last step
  return steps.length;
};

export const guideSteps: Step[] = [
  {
    target: '#step-overview',
    title: 'Velkommen til Smart Video',
    content:
      'Dette er dit overblik over de trin, du skal igennem for at lave din video. Du kan altid se, hvor langt du er i processen via procesbaren. Når alle trinene er gennemført, er din video klar.',
    disableBeacon: true,
  },
  {
    target: '#step-navigation',
    title: 'Navigering',
    content:
      'Du kan altid skifte trin ved at trykke på knapperne. Det næste trin er inaktivt, indtil du har udfyldt de nødvendige felter.',
    disableBeacon: true,
  },
  // --- Assets --- //
  {
    target: '#assets-grid',
    title: 'Fil oversigt',
    content: 'Her kan du se alle filer, du har tilføjet til din video.',
    disableBeacon: true,
  },
  {
    target: '#assets-add-asset',
    title: 'Tilføj filer',
    content:
      'Tryk på knappen for at tilføje en ny fil til din video. Du kan vælge mellem filer, der allerede er tilføjet eller tilføje en ny fil.',
    disableBeacon: true,
  },
  {
    target: '#assets-asset',
    title: 'Fil',
    content:
      'Her kan du se din fil. Du kan se, hvilken fil det er, hvilke effekter der er tilføjet og hvilken placering filen har i videoen. Du kan desuden også redigere og slette filen.',
    disableBeacon: true,
  },
  {
    target: '#assets-move-asset',
    title: 'Fil rækkefølge',
    content:
      'Du kan så let som ingenting ændre rækkefølgen på dine filer ved at tage fat i ikonet og rykke rundt på dem.',
    disableBeacon: true,
  },
  {
    target: '#assets-move-asset-arrows',
    title: 'Fil rækkefølge',
    content:
      'Du kan så let som ingenting ændre rækkefølgen på dine filer ved at bruge pilene. Du kan flytte filen op og ned i rækkefølgen.',
    disableBeacon: true,
  },
  {
    target: '#assets-add-asset-mutation',
    title: 'Tilføj fil effekter',
    content:
      'Du kan tilføje effekter til dine filer ved at trykke på knappen. Du kan vælge mellem forskellige effekter og tilpasse dem efter behov. Nogle effekter kræver, at du har en bestemt filtype, mens andre kun vil forekomme en gang på filen.',
    disableBeacon: true,
  },
  {
    target: '#assets-replace-asset',
    title: 'Udskift fil',
    content:
      'Denne fil er en kopi fra skabelonen. Du kan udskifte filen ved at trykke på knappen. Du kan også redigere filen, så den passer til dine behov.',
    disableBeacon: true,
  },
  {
    target: '#assets-approve-asset',
    title: 'Behold fil',
    content:
      'Denne fil er en kopi fra skabelonen. Du kan beholde filen ved at trykke på knappen. Du kan også redigere filen, så den passer til dine behov.',
    disableBeacon: true,
  },
  {
    target: '#assets-edit-asset',
    title: 'Rediger fil',
    content:
      'Tryk på knappen for at skifte filen ud eller ændre på det navn, den har i oversigten.',
    disableBeacon: true,
  },
  {
    target: '#assets-delete-asset',
    title: 'Slet fil',
    content: 'Tryk på knappen for at slette filen.',
    disableBeacon: true,
  },
  {
    target: '#assets-edit-asset-mutation',
    title: 'Rediger fil-effekter',
    content: 'Tryk på knappen for redigere en fil-effekt.',
    disableBeacon: true,
  },
  {
    target: '#assets-delete-asset-mutation',
    title: 'Slet fil-effekter',
    content: 'Tryk på knappen for at slette en fil-effekt.',
    disableBeacon: true,
  },
  {
    target: '#assets-remove-assets',
    title: 'Slet alle filer',
    content: 'Tryk på knappen for at slette alle filerne.',
    disableBeacon: true,
  },
  // --- Branches --- //
  {
    target: '#branches-overview',
    title: 'Format oversigt',
    content: 'Her kan du se alle de formater, din video vil blive lavet i.',
    disableBeacon: true,
  },
  {
    target: '#branches-add-branch',
    title: 'Tilføj formater',
    content:
      'Tryk på knappen for at tilføje et nyt format til din video. Du kan vælge mellem forskellige formater og tilpasse dem efter dit behov.',
    disableBeacon: true,
  },
  {
    target: '#branches-edit-branch',
    title: 'Rediger format',
    content: 'Tryk på knappen for at redigere et format.',
    disableBeacon: true,
  },
  {
    target: '#branches-delete-branch',
    title: 'Slet format',
    content: 'Tryk på knappen for at slette et format.',
    disableBeacon: true,
  },
  // --- Branch mutations --- //
  {
    target: '#branch-mutations-overview',
    title: 'Videoeffekter oversigt',
    content:
      'Her kan du se alle de videoeffekter, der er tilføjet til din video.',
    disableBeacon: true,
  },
  {
    target: '#branch-mutations-add-mutation',
    title: 'Tilføj videoeffekt',
    content:
      'Tryk på knappen for at tilføje en ny videoeffekt til din video. Du kan vælge mellem forskellige effekter og tilpasse dem efter dit behov. Nogle effekter kræver, at du har en bestemt filtype, mens andre kun vil forekomme en gang på hver fil.',
    disableBeacon: true,
  },
  {
    target: '#branch-mutations-edit-mutation',
    title: 'Rediger videoeffekt',
    content: 'Tryk på knappen for at redigere en videoeffekt.',
    disableBeacon: true,
  },
  {
    target: '#branch-mutations-delete-mutation',
    title: 'Slet videoeffekt',
    content: 'Tryk på knappen for at slette en videoeffekt.',
    disableBeacon: true,
  },
  // --- Subtitles --- //
  {
    target: '#subtitles-generate-subtitles',
    title: 'Lav undertekster',
    content:
      'Tryk på knappen, for at starte processen, så du kan lave undertekster til din video. Når du har startet processen, kan du ikke længere ændre i videoen.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-edit-subtitles-area',
    title: 'Rediger undertekster',
    content:
      'Her kan du se og redigere underteksterne til din video. Du kan ændre i dem, så meget du vil, indtil du har godkendt dem.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-preview',
    title: 'Forhåndsvisning af undertekster',
    content:
      'Du kan afspille videoen for at se, hvordan underteksterne ser ud i videoen.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-edit-subtitles',
    title: 'Opdater undertekster',
    content:
      'Tryk på knappen for at opdatere underteksterne til din video. Du kan ændre i dem, så meget du vil, indtil du har godkendt dem.',
    disableBeacon: true,
  },
  {
    target: '#subtitles-approve-subtitles',
    title: 'Godkend undertekster',
    content:
      'Tryk på knappen for at godkende underteksterne til din video. Når du har godkendt dem, kan de ikke længere ændres.',
    disableBeacon: true,
  },
  // --- Result --- //
  {
    target: '#results-finalize',
    title: 'Færddiggør video',
    content:
      'Tryk på knappen for at færdiggøre din video. Når du har færdiggjort din video, kan du ikke længere ændre i den. Sørg for at alt er, som det skal være, før du trykker på knappen.',
    disableBeacon: true,
  },
  {
    target: '#results-overview',
    title: 'Resultater',
    content:
      'Her kan du se resultaterne af din video. Der vil være et resulat for hvert format.',
    disableBeacon: true,
  },
  {
    target: '#results-result',
    title: 'Resultat',
    content: 'Du kan afspille resultatet af videoerne her og downloade dem.',
    disableBeacon: true,
  },
];
