import { twMerge } from 'tailwind-merge';

export type DotType = 'primary' | 'info' | 'error';

interface BadgeCellProps {
  text: string;
  type?: 'primary' | 'info' | 'success' | 'warning' | 'error';
  className?: string;
  dotType?: DotType;
}

export const BadgeCell = ({
  text,
  type,
  className,
  dotType,
}: BadgeCellProps) => {
  return (
    <div className={twMerge(`badge badge-${type ?? 'primary'}`, className)}>
      {dotType ? (
        <div
          className={twMerge('w-1.5 h-1.5 rounded-full mr-2', `bg-${dotType}`)}
        ></div>
      ) : null}
      {text}
    </div>
  );
};
