/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EDynamicTemplateType = {
    Overlay: 'Overlay',
    Transition: 'Transition'
} as const;
export type EDynamicTemplateType = typeof EDynamicTemplateType[keyof typeof EDynamicTemplateType];


export function EDynamicTemplateTypeFromJSON(json: any): EDynamicTemplateType {
    return EDynamicTemplateTypeFromJSONTyped(json, false);
}

export function EDynamicTemplateTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EDynamicTemplateType {
    return json as EDynamicTemplateType;
}

export function EDynamicTemplateTypeToJSON(value?: EDynamicTemplateType | null): any {
    return value as any;
}

