import {
  MediaSequenceCategoryCreateRequest,
  MediaSequenceCategoryResponse,
  MediaSequenceCategoryUpdateRequest,
} from 'api/core';
import {
  useCreateMediaSequenceCategory,
  useUpdateMediaSequenceCategory,
} from 'api/useMediaSequenceCategoriesApi';
import { useForm } from 'react-hook-form';

interface MediaSequenceCategoryFormProps {
  targetCategory?: MediaSequenceCategoryResponse;
  onSuccess: () => void;
  onCancel: () => void;
}

export const MediaSequenceCategoryForm = ({
  targetCategory,
  onSuccess,
  onCancel,
}: MediaSequenceCategoryFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceCategory();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceCategory();

  const { register, handleSubmit } = useForm<
    MediaSequenceCategoryCreateRequest | MediaSequenceCategoryUpdateRequest
  >({
    defaultValues: targetCategory,
  });

  const onSubmit = handleSubmit(async (result) => {
    if (targetCategory) {
      await updateAsync({
        id: targetCategory.id,
        mediaSequenceCategoryUpdateRequest: result,
      });
    } else {
      await createAsync({
        mediaSequenceCategoryCreateRequest: result,
      });
    }
    onSuccess();
  });

  return (
    <form onSubmit={onSubmit} className="space-y-2">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Navn</span>
        </label>
        <input
          {...register('name', { required: true })}
          type="text"
          className="input input-bordered"
        />
      </div>

      <div className="flex justify-center space-x-4 pt-4">
        <button
          className="btn btn-primary"
          disabled={isPendingCreate || isPendingUpdate}
        >
          {targetCategory ? 'Opdater' : 'Opret'}
        </button>
        {onCancel ? (
          <button type="button" className="btn" onClick={onCancel}>
            Annuller
          </button>
        ) : null}
      </div>
    </form>
  );
};
