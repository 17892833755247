/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ETemplatePosition } from './ETemplatePosition';
import {
    ETemplatePositionFromJSON,
    ETemplatePositionFromJSONTyped,
    ETemplatePositionToJSON,
} from './ETemplatePosition';

/**
 * 
 * @export
 * @interface MediaSequenceAssetUpdateRequest
 */
export interface MediaSequenceAssetUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetUpdateRequest
     */
    assetId: string;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetUpdateRequest
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof MediaSequenceAssetUpdateRequest
     */
    isTemplate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MediaSequenceAssetUpdateRequest
     */
    isCopy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MediaSequenceAssetUpdateRequest
     */
    description?: string | null;
    /**
     * 
     * @type {ETemplatePosition}
     * @memberof MediaSequenceAssetUpdateRequest
     */
    templatePosition?: ETemplatePosition;
}

/**
 * Check if a given object implements the MediaSequenceAssetUpdateRequest interface.
 */
export function instanceOfMediaSequenceAssetUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "assetId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function MediaSequenceAssetUpdateRequestFromJSON(json: any): MediaSequenceAssetUpdateRequest {
    return MediaSequenceAssetUpdateRequestFromJSONTyped(json, false);
}

export function MediaSequenceAssetUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MediaSequenceAssetUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assetId': json['assetId'],
        'name': json['name'],
        'isTemplate': !exists(json, 'isTemplate') ? undefined : json['isTemplate'],
        'isCopy': !exists(json, 'isCopy') ? undefined : json['isCopy'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'templatePosition': !exists(json, 'templatePosition') ? undefined : ETemplatePositionFromJSON(json['templatePosition']),
    };
}

export function MediaSequenceAssetUpdateRequestToJSON(value?: MediaSequenceAssetUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assetId': value.assetId,
        'name': value.name,
        'isTemplate': value.isTemplate,
        'isCopy': value.isCopy,
        'description': value.description,
        'templatePosition': ETemplatePositionToJSON(value.templatePosition),
    };
}

