/* tslint:disable */
/* eslint-disable */
/**
 * EstateEngine.Core.Api - All endpoints
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MediaSequenceCategoryCreateRequest,
  MediaSequenceCategoryResponse,
  MediaSequenceCategoryResponsePagedData,
  MediaSequenceCategoryUpdateRequest,
} from '../models';
import {
    MediaSequenceCategoryCreateRequestFromJSON,
    MediaSequenceCategoryCreateRequestToJSON,
    MediaSequenceCategoryResponseFromJSON,
    MediaSequenceCategoryResponseToJSON,
    MediaSequenceCategoryResponsePagedDataFromJSON,
    MediaSequenceCategoryResponsePagedDataToJSON,
    MediaSequenceCategoryUpdateRequestFromJSON,
    MediaSequenceCategoryUpdateRequestToJSON,
} from '../models';

export interface MediaOrbitMediaSequenceCategoriesGetRequest {
    pageNumber?: number;
    size?: number;
    orderByProperty?: string;
    ascending?: boolean;
    searchTerm?: string;
}

export interface MediaOrbitMediaSequenceCategoriesIdDeleteRequest {
    id: string;
}

export interface MediaOrbitMediaSequenceCategoriesIdGetRequest {
    id: string;
}

export interface MediaOrbitMediaSequenceCategoriesIdPutRequest {
    id: string;
    mediaSequenceCategoryUpdateRequest?: MediaSequenceCategoryUpdateRequest;
}

export interface MediaOrbitMediaSequenceCategoriesPostRequest {
    mediaSequenceCategoryCreateRequest?: MediaSequenceCategoryCreateRequest;
}

export interface MediaOrbitMediaSequenceCategoriesSearchGetRequest {
    size?: number;
    searchTerm?: string;
}

/**
 * 
 */
export class MediaSequenceCategoriesApi extends runtime.BaseAPI {

    /**
     */
    async mediaOrbitMediaSequenceCategoriesGetRaw(requestParameters: MediaOrbitMediaSequenceCategoriesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceCategoryResponsePagedData>> {
        const queryParameters: any = {};

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.orderByProperty !== undefined) {
            queryParameters['orderByProperty'] = requestParameters.orderByProperty;
        }

        if (requestParameters.ascending !== undefined) {
            queryParameters['ascending'] = requestParameters.ascending;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/MediaSequenceCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceCategoryResponsePagedDataFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesGet(requestParameters: MediaOrbitMediaSequenceCategoriesGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceCategoryResponsePagedData> {
        const response = await this.mediaOrbitMediaSequenceCategoriesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesIdDeleteRaw(requestParameters: MediaOrbitMediaSequenceCategoriesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceCategoryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaOrbitMediaSequenceCategoriesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/MediaSequenceCategories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceCategoryResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesIdDelete(requestParameters: MediaOrbitMediaSequenceCategoriesIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceCategoryResponse> {
        const response = await this.mediaOrbitMediaSequenceCategoriesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesIdGetRaw(requestParameters: MediaOrbitMediaSequenceCategoriesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceCategoryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaOrbitMediaSequenceCategoriesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/MediaSequenceCategories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceCategoryResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesIdGet(requestParameters: MediaOrbitMediaSequenceCategoriesIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceCategoryResponse> {
        const response = await this.mediaOrbitMediaSequenceCategoriesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesIdPutRaw(requestParameters: MediaOrbitMediaSequenceCategoriesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceCategoryResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mediaOrbitMediaSequenceCategoriesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/MediaSequenceCategories/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceCategoryUpdateRequestToJSON(requestParameters.mediaSequenceCategoryUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceCategoryResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesIdPut(requestParameters: MediaOrbitMediaSequenceCategoriesIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceCategoryResponse> {
        const response = await this.mediaOrbitMediaSequenceCategoriesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesPostRaw(requestParameters: MediaOrbitMediaSequenceCategoriesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MediaSequenceCategoryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/MediaSequenceCategories`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MediaSequenceCategoryCreateRequestToJSON(requestParameters.mediaSequenceCategoryCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MediaSequenceCategoryResponseFromJSON(jsonValue));
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesPost(requestParameters: MediaOrbitMediaSequenceCategoriesPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MediaSequenceCategoryResponse> {
        const response = await this.mediaOrbitMediaSequenceCategoriesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesSearchGetRaw(requestParameters: MediaOrbitMediaSequenceCategoriesSearchGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MediaSequenceCategoryResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/MediaOrbit/MediaSequenceCategories/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MediaSequenceCategoryResponseFromJSON));
    }

    /**
     */
    async mediaOrbitMediaSequenceCategoriesSearchGet(requestParameters: MediaOrbitMediaSequenceCategoriesSearchGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MediaSequenceCategoryResponse>> {
        const response = await this.mediaOrbitMediaSequenceCategoriesSearchGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
