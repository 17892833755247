import { ControlledModal } from 'components/ControlledModal';
import { useState } from 'react';
import { MediaSequenceCategoryForm } from './MediaSequenceCategoryForm';
import { useGetMediaSequenceCategory } from 'api/useMediaSequenceCategoriesApi';

interface MediaSequenceCategoryUpdateProps {
  id?: string;
  trigger?: React.ReactNode;
  isInitialOpen?: boolean;
  onClosed?: () => void;
}

export const MediaSequenceCategoryUpdate = ({
  id,
  trigger,
  isInitialOpen,
  onClosed,
}: MediaSequenceCategoryUpdateProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(isInitialOpen ?? false);

  const { data } = useGetMediaSequenceCategory(id);

  if (!data) return null;

  const onModalStateChange = (state: boolean) => {
    setIsOpen(state);
    if (!state) {
      onClosed?.();
    }
  };

  return (
    <>
      {trigger ? (
        <div onClick={() => onModalStateChange(true)}>{trigger}</div>
      ) : null}
      <ControlledModal
        showModal={onModalStateChange}
        isOpen={isOpen}
        title="Opdater smart video kategori"
        closeOnOutsideClick
        hideActionBar
      >
        <MediaSequenceCategoryForm
          targetCategory={data}
          onSuccess={() => onModalStateChange(false)}
          onCancel={() => onModalStateChange(false)}
        />
      </ControlledModal>
    </>
  );
};
