import {
  useBeginCreateAsset,
  useFinalizeCreateAsset,
  useUploadPresignedFileUrl,
} from 'api/useAssetsApi';
import { FullscreenLoadingWithText } from 'components/Loading';
import { useState } from 'react';
import {
  calculateChecksumAsync,
  convertPdfToImageAsync,
  getMetadataForAudioAsync,
  getMetadataForImageAsync,
  getMetadataForVideoAsync,
} from 'utils/file-util';
import { EnterAddressDialog } from './EnterAddressDialog';
import {
  AssetMetadataWithValue,
  AssetResponse,
  EAssetSource,
  EEntityState,
} from 'api/core';

interface AssetUploadProps {
  fileInputRef: React.RefObject<HTMLInputElement>;
  projectId?: string;
  onAssetUploaded?: (asset: AssetResponse) => void;
  accept?: string;
  allowMultiple?: boolean;
  source?: EAssetSource;
  enableAddressNaming?: boolean;
  convertPdfToImage?: boolean;
}

export const AssetUpload = ({
  fileInputRef,
  projectId,
  onAssetUploaded,
  accept,
  allowMultiple,
  source,
  enableAddressNaming,
  convertPdfToImage = false,
}: AssetUploadProps) => {
  const { mutateAsync: createAssetAsync } = useBeginCreateAsset();
  const { mutateAsync: uploadFileAsync } = useUploadPresignedFileUrl();
  const { mutateAsync: markAsync } = useFinalizeCreateAsset();
  const [isUploading, setIsUploading] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [uploadFn, setUploadFn] =
    useState<(address: string | null) => Promise<void>>();
  const [showRenameDialog, setShowRenameDialog] = useState(false);

  const handleFileUpload = async (file: File, address?: string | null) => {
    setIsUploading(true);

    // If PDF file, convert to image
    if (
      convertPdfToImage &&
      (file.type === 'application/pdf' ||
        file.name.toLocaleLowerCase().endsWith('.pdf'))
    ) {
      await convertPdfToImageAsync(file).then((image: Blob) => {
        const newFilename =
          (file.name.toLocaleLowerCase().endsWith('.pdf')
            ? file.name.substring(0, file.name.length - 4)
            : file.name) + '.png';
        file = new File([image], newFilename, {
          type: 'image/png',
        });
      });
    }

    let metadata: AssetMetadataWithValue[] = [];
    try {
      if (file.type.startsWith('video/')) {
        metadata = await getMetadataForVideoAsync(file);
      } else if (file.type.startsWith('image/')) {
        metadata = await getMetadataForImageAsync(file);
      } else if (file.type.startsWith('audio/')) {
        metadata = await getMetadataForAudioAsync(file);
      }
    } catch (error) {
      console.warn('Error getting video duration', error);
    }

    const sha256 = await calculateChecksumAsync(file);
    const fileName = address ? `${address}, ${file.name}` : file.name;
    const assetResponse = await createAssetAsync({
      assetCreateRequest: {
        projectId,
        entityState: EEntityState.Active,
        fileName,
        mimeType: file.type,
        fileSize: file.size,
        checksumSha256: sha256,
        source: source || EAssetSource.UserUploaded,
        metadata,
      },
    });

    if (assetResponse.foundMatchingAsset) {
      setIsUploading(false);
      onAssetUploaded?.({
        id: assetResponse.id,
        projectId: assetResponse.projectId,
        lastModifiedUtc: assetResponse.lastModifiedUtc,
        createdUtc: assetResponse.createdUtc,
        type: assetResponse.type,
        fileSize: assetResponse.fileSize,
        isGlobal: false,
        originalFileName: assetResponse.originalFileName,
        tags: [],
        url: assetResponse?.url || '',
        previewUrl: assetResponse.previewUrl,
        userId: assetResponse.userId,
        source: assetResponse.source,
      });
      return;
    }

    await uploadFileAsync({
      url: assetResponse.presignedUploadUrl,
      file,
    });

    const result = await markAsync({ id: assetResponse.id });
    onAssetUploaded?.(result);
    setIsUploading(false);
  };

  const handleFiles = async (files: FileList) => {
    setFilesCount(files.length);
    if (files.length < 1) return;

    const startUploadingAsync = async (address: string | null) => {
      setFilesUploaded(1);
      setIsUploading(true);
      for (let i = 0; i < files.length; i++) {
        await handleFileUpload(files[i], address);
        setFilesUploaded(i + 1);
      }
      setIsUploading(false);
    };

    if (files.length === 1 || !enableAddressNaming) {
      await startUploadingAsync(null);
    } else {
      setUploadFn(
        () => (address: string | null) => startUploadingAsync(address)
      );
      setShowRenameDialog(true);
    }
  };

  return (
    <>
      <input
        type="file"
        multiple={allowMultiple}
        ref={fileInputRef}
        accept={accept}
        onChange={async (event) => {
          const files = event.target.files;
          if (!files) return;
          await handleFiles(files);
        }}
        className="hidden"
      />
      {isUploading ? (
        <FullscreenLoadingWithText
          text={`Uploader fil ${filesUploaded} ud af ${filesCount}...`}
          icon="upload-icon"
        />
      ) : null}
      {enableAddressNaming && (
        <EnterAddressDialog
          isOpen={showRenameDialog}
          setIsOpen={setShowRenameDialog}
          fileCount={filesCount}
          onAddressConfirmed={(address) => {
            setShowRenameDialog(false);
            uploadFn?.(address);
          }}
          onCancel={() => {
            setShowRenameDialog(false);
            uploadFn?.(null);
            setUploadFn(undefined);
          }}
        />
      )}
    </>
  );
};
