import { ColumnDef } from '@tanstack/react-table';
import { LanguageResponse } from 'api/core';
import { CustomTable } from 'components/Table/CustomTable';
import { useGetCustomReactTable } from 'components/Table/useGetTableSet';
import { TableQueryState } from 'components/Table/useTableQueryState';
import { Link } from 'react-router-dom';

interface TableProps {
  data: LanguageResponse[];
  isFetching: boolean;
  queryState: TableQueryState;
}

const columns: ColumnDef<LanguageResponse>[] = [
  {
    accessorKey: 'id',
    header: '#',
    cell: (e) => (
      <Link to={`/admin/config/languages/${e.row.original.id}`}>
        {e.row.original.title}
      </Link>
    ),
  },
];

export const LanguagesTable = ({
  data,
  isFetching,
  queryState,
}: TableProps) => {
  const table = useGetCustomReactTable(data ?? [], 1, columns, queryState);

  return <CustomTable table={table} isLoading={isFetching} />;
};
