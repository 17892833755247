import {
  AssetSimpleResponse,
  EAssetType,
  EPermission,
  ETemplatePosition,
  MediaSequenceAssetResponse,
  MediaSequenceAssetUpdateRequest,
} from 'api/core';
import {
  useCreateMediaSequenceAsset,
  useUpdateMediaSequenceAsset,
} from 'api/useMediaSequenceAssetsApi';
import { useGetMediaSequence } from 'api/useMediaSequencesApi';
import { useAuth } from 'auth/AuthProvider';
import { AssetUpload } from 'components/Form/AssetUpload';
import { LabelWithHelperText } from 'components/Form/LabelWithHelperText';
import { AssetSelectSingleAsync } from 'components/Select/AssetSelectSingleAsync';
import {
  isTemplate as isMedSeqTemplate,
  MEDIA_SEQUENCE_ACCEPTED_FILES,
} from 'pages/MediaOrbit/media-sequence-utils';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { positionTranslate } from 'utils/enum-translate';

interface MediaSequenceAssetFormProps {
  targetAsset?: MediaSequenceAssetResponse;
  mediaSequenceId?: string;
  projectId?: string;
  onSuccess?: (asset: MediaSequenceAssetResponse) => void;
  onCancel?: () => void;
  inDialog?: boolean;
}

export const MediaSequenceAssetForm = ({
  targetAsset,
  mediaSequenceId,
  projectId,
  onSuccess,
  onCancel,
  inDialog,
}: MediaSequenceAssetFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceAsset();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceAsset();

  const { data: mediaSequence } = useGetMediaSequence(
    targetAsset?.mediaSequenceId
  );
  const { hasPermissions } = useAuth();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [initialSelectedAsset, setInitialSelectedAsset] = useState<
    AssetSimpleResponse | undefined
  >(targetAsset?.asset);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { touchedFields },
    watch,
  } = useForm<MediaSequenceAssetUpdateRequest>({
    defaultValues: targetAsset
      ? {
          name: targetAsset.name,
          assetId: targetAsset.asset.id,
          description: targetAsset.description,
          isCopy: targetAsset.isCopy,
          isTemplate: targetAsset.isTemplate,
          templatePosition: targetAsset.templatePosition,
        }
      : undefined,
  });

  const isAssetTemplate = watch('isTemplate');
  useEffect(() => {
    if (isAssetTemplate && !watch('templatePosition')) {
      setValue('templatePosition', ETemplatePosition.First);
    }
  }, [isAssetTemplate, setValue, watch]);

  const isTemplate =
    mediaSequence &&
    isMedSeqTemplate(mediaSequence) &&
    hasPermissions([EPermission.MediaSequenceGlobalTemplateAdministrator]);

  const onSubmit = handleSubmit(async (result) => {
    if (!result.isTemplate) result.templatePosition = undefined;
    if (targetAsset) {
      const assetResponse = await updateAsync({
        id: targetAsset.id,
        mediaSequenceAssetUpdateRequest: result,
      });
      onSuccess?.(assetResponse);
    } else {
      if (!mediaSequenceId) return;
      const assetResponse = await createAsync({
        mediaSequenceId,
        mediaSequenceAssetCreateRequest: result,
      });
      onSuccess?.(assetResponse);
    }
  });

  const onAssetSelected = (asset: AssetSimpleResponse | null) => {
    if (!asset) return;
    if ((!targetAsset && !touchedFields.name) || getValues('name') === '') {
      setValue('name', asset.originalFileName);
    }
    setValue('assetId', asset.id);
    setInitialSelectedAsset(asset);
  };

  return (
    <>
      <AssetUpload
        fileInputRef={fileInputRef}
        projectId={projectId}
        onAssetUploaded={(asset) => onAssetSelected(asset)}
        accept={MEDIA_SEQUENCE_ACCEPTED_FILES}
      />
      <form onSubmit={onSubmit} className="space-y-2">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Fil</span>
          </label>
          <AssetSelectSingleAsync
            // Allow the user to seearch in all assets on organization (Not just project assets)
            // projectId={projectId}
            types={[EAssetType.Image, EAssetType.Video]}
            onSelected={onAssetSelected}
            initialValue={initialSelectedAsset}
            showCreateOption
            onCreateOptionSelected={() => fileInputRef.current?.click()}
            inDialog={inDialog}
          />
          <input
            {...register('assetId', { required: true })}
            type="text"
            className="hidden"
          />
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Navn</span>
          </label>
          <input
            {...register('name', { required: true })}
            type="text"
            className="input input-bordered"
          />
        </div>

        {isTemplate ? (
          <>
            <div className="form-control">
              <LabelWithHelperText
                label="IsCopy"
                helperText="Hvis denne er true, SKAL brugeren uploade en fil her."
                htmlFor="isCopy"
              />
              <input
                {...register('isCopy', { required: false })}
                type="checkbox"
                className="toggle toggle-primary"
                id="isCopy"
                disabled={watch('isTemplate')}
              />
            </div>

            <div className="form-control">
              <LabelWithHelperText
                label="IsTemplate"
                helperText="Hvis denne er true, kan brugeren IKKE erstatte filen."
                htmlFor="isTemplate"
              />
              <input
                {...register('isTemplate', { required: false })}
                type="checkbox"
                className="toggle toggle-primary"
                id="isTemplate"
                disabled={watch('isCopy')}
              />
            </div>

            <div className="form-control">
              <LabelWithHelperText
                label="TemplatePosition"
                helperText="Hvor i sekvensen skal denne asset være fastlåst?"
                htmlFor="templatePosition"
              />
              <select
                {...register('templatePosition')}
                className="select select-bordered"
                id="templatePosition"
                disabled={!watch('isTemplate')}
              >
                {[ETemplatePosition.First, ETemplatePosition.Last].map(
                  (position) => (
                    <option key={position} value={position}>
                      {positionTranslate(position)}
                    </option>
                  )
                )}
              </select>
            </div>

            <div className="form-control">
              <label className="label">
                <span className="label-text">Beskrivelse</span>
              </label>
              <input
                {...register('description', { required: false })}
                type="text"
                className="input input-bordered"
              />
            </div>
          </>
        ) : null}

        <div className="flex justify-center space-x-4 pt-4">
          <button
            className="btn btn-primary"
            disabled={isPendingCreate || isPendingUpdate}
          >
            {targetAsset ? 'Opdater' : 'Tilføj'}
          </button>
          {onCancel ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </>
  );
};
