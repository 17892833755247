import { AnimatedIcon } from 'components/Icon/AnimatedIcon';
import { Tooltip } from 'components/Tooltip';
import { Link } from 'components/Typography/Link';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface LinkCellProps {
  path: string;
  icon?: ReactNode;
  tooltip?: string;
  isExternal?: boolean;
  disabled?: boolean;
}

export const LinkCell = ({
  path,
  icon,
  tooltip,
  isExternal,
  disabled,
}: LinkCellProps) => {
  return (
    <Tooltip tooltip={tooltip}>
      <Link to={path} target={isExternal ? '_blank' : undefined}>
        <button className="text-primary-content" disabled={disabled}>
          {icon ? (
            icon
          ) : (
            <AnimatedIcon
              icon="open-icon"
              disablePlayOnHover={disabled}
              className={twMerge(
                'h-6 w-6',
                disabled && 'text-primary-content opacity-20'
              )}
            />
          )}
        </button>
      </Link>
    </Tooltip>
  );
};
