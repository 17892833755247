import {
  EAssetMutationType,
  EAssetType,
  MediaSequenceAssetMutationResponse,
  MediaSequenceAssetResponse,
  MediaSequenceBranchMutationResponse,
  MediaSequenceResponse,
} from 'api/core';
import { EBranchMutationType } from './../api/core/models/EBranchMutationType';

const VIDEO_MUTATIONS: EAssetMutationType[] = [
  EAssetMutationType.TrimVideo, // Must be first
  EAssetMutationType.Voiceover,
  EAssetMutationType.AddDynamicOverlay,
  EAssetMutationType.ImageOverlay,
  // EAssetMutationType.ColorAdjustments,
];

const IMAGE_MUTATIONS: EAssetMutationType[] = [
  EAssetMutationType.ImageToVideo,
  EAssetMutationType.AddDynamicOverlay,
  EAssetMutationType.ImageOverlay,
  EAssetMutationType.Voiceover,
];

const DUPLICTABLE_MUTATIONS: EAssetMutationType[] = [
  EAssetMutationType.ImageOverlay,
  EAssetMutationType.AddDynamicOverlay,
];

export const getAvailableAssetMutations = (
  asset: MediaSequenceAssetResponse,
  targetMutation?: MediaSequenceAssetMutationResponse
) => {
  const availableMutations: EAssetMutationType[] = [];

  // Always push the target mutation into the array
  if (targetMutation) {
    availableMutations.push(targetMutation.type);
  }

  const mutationsForAssetType =
    asset.asset.type === EAssetType.Video ? VIDEO_MUTATIONS : IMAGE_MUTATIONS;

  // Add all relevant mutations
  mutationsForAssetType.forEach((mutation) => {
    if (
      DUPLICTABLE_MUTATIONS.includes(mutation) ||
      !asset.mutations.some((m) => m.type === mutation)
    ) {
      availableMutations.push(mutation);
    }
  });

  return availableMutations;
};

// This needs to be in same order as in the form else initial selected bugs
const AVAILABLE_BRANCH_MUTATIONS: EBranchMutationType[] = [
  EBranchMutationType.AddDynamicOverlay,
  EBranchMutationType.AddWatermark,
  EBranchMutationType.ResizeAndConcat,
];

const DUPLICATE_BRANCH_MUTATIONS: EBranchMutationType[] = [
  EBranchMutationType.AddDynamicOverlay,
  EBranchMutationType.AddWatermark,
];

export const getAvailableBranchMutations = (
  mediaSequence: MediaSequenceResponse,
  targetBranchMutation?: MediaSequenceBranchMutationResponse
) => {
  const availableMutations: EBranchMutationType[] = [];

  // Always push the target mutation into the array
  if (targetBranchMutation) {
    availableMutations.push(targetBranchMutation.type);
  }

  // Push all non existing (or duplicatable) mutations into the array
  AVAILABLE_BRANCH_MUTATIONS.forEach((mutation) => {
    if (
      DUPLICATE_BRANCH_MUTATIONS.includes(mutation) ||
      !mediaSequence.branchMutations.some((m) => m.type === mutation)
    ) {
      availableMutations.push(mutation);
    }
  });

  return availableMutations;
};
