import {
  EBranchMutationType,
  MediaSequenceBranchMutationCreateRequest,
  MediaSequenceBranchMutationResponse,
  MediaSequenceBranchMutationUpdateRequest,
  MediaSequenceResponse,
} from 'api/core';
import {
  useCreateMediaSequenceBranchMutation,
  useUpdateMediaSequenceBranchMutation,
} from 'api/useMediaSequenceBranchesApi';
import { FormProvider, useForm } from 'react-hook-form';
import { getAvailableBranchMutations } from 'utils/available-mutations';
import { branchMutationTranslate } from 'utils/enum-translate';
import { AddDynamicOverlay } from './Types/AddDynamicOverlay';
import { ResizeAndContact } from './Types/ResizeAndConcat';
import { AddWatermark } from './Types/AddWatermark';
import { twMerge } from 'tailwind-merge';
import { useEffect, useState } from 'react';

interface MediaSequenceBranchMutationFormProps {
  mediaSequence: MediaSequenceResponse;
  targetBranchMutation?: MediaSequenceBranchMutationResponse;
  onSuccess?: () => void;
  onCancel?: () => void;
  inDialog?: boolean;
  disabled?: boolean;
}

export const MediaSequenceBranchMutationForm = ({
  mediaSequence,
  targetBranchMutation,
  onSuccess,
  onCancel,
  inDialog,
  disabled,
}: MediaSequenceBranchMutationFormProps) => {
  const { mutateAsync: createAsync, isPending: isPendingCreate } =
    useCreateMediaSequenceBranchMutation();
  const { mutateAsync: updateAsync, isPending: isPendingUpdate } =
    useUpdateMediaSequenceBranchMutation();

  const methods = useForm<
    | MediaSequenceBranchMutationCreateRequest
    | MediaSequenceBranchMutationUpdateRequest
  >({
    defaultValues: targetBranchMutation,
  });

  const { register, handleSubmit, watch, control } = methods;

  const [
    fieldIdsOfCurrentDynamicTemplate,
    setFieldIdsOfCurrentDynamicTemplate,
  ] = useState<string[]>([]);

  // We have to disable the form like this, if we set it in the useForm constructor it will not load in default values
  useEffect(() => {
    control._disableForm(disabled);
  }, [control, disabled]);

  // If the order of the mutations change, the initial selected value will be wrong
  const availableMutations = getAvailableBranchMutations(
    mediaSequence,
    targetBranchMutation
  );
  const typeSelected = watch('type') ?? availableMutations[0];

  const onSubmit = handleSubmit(async (result) => {
    if (typeSelected === EBranchMutationType.AddBackgroundMusic) {
      result.addWatermark = undefined;
      result.resizing = undefined;
      result.addDynamicOverlay = undefined;
    } else if (typeSelected === EBranchMutationType.AddWatermark) {
      result.addBackgroundMusic = undefined;
      result.resizing = undefined;
      result.addDynamicOverlay = undefined;
    } else if (typeSelected === EBranchMutationType.ResizeAndConcat) {
      result.addBackgroundMusic = undefined;
      result.addWatermark = undefined;
      result.addDynamicOverlay = undefined;
    } else if (typeSelected === EBranchMutationType.AddDynamicOverlay) {
      result.addBackgroundMusic = undefined;
      result.addWatermark = undefined;
      result.resizing = undefined;
      if (result.addDynamicOverlay) {
        result.addDynamicOverlay.dynamicTemplateFieldValues =
          // only keep the keys where the key ends with a id from "fieldIdsOfCurrentDynamicTemplate"
          // this is to remove all the other fields that are not in the current dynamic template
          Object.fromEntries(
            Object.entries(
              result.addDynamicOverlay.dynamicTemplateFieldValues
            ).filter(([key]) =>
              fieldIdsOfCurrentDynamicTemplate.includes(key.slice(-36))
            )
          );
      }
    }
    if (targetBranchMutation) {
      await updateAsync({
        id: targetBranchMutation.id,
        mediaSequenceBranchMutationUpdateRequest: result,
      });
    } else {
      if (!mediaSequence.id) return;
      await createAsync({
        id: mediaSequence.id,
        mediaSequenceBranchMutationCreateRequest: result,
      });
    }
    onSuccess?.();
  });

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={onSubmit}
        className={twMerge(
          'space-y-2',
          typeSelected !== EBranchMutationType.AddDynamicOverlay &&
            'w-[80vw] md:w-[512px]',
          typeSelected === EBranchMutationType.AddDynamicOverlay && 'w-full'
        )}
      >
        <div className="form-control">
          <label className="label">
            <span className="label-text">Type</span>
          </label>
          <select
            {...register('type', { required: true })}
            className="select select-bordered"
            disabled={typeSelected === EBranchMutationType.ResizeAndConcat}
          >
            {[
              EBranchMutationType.AddDynamicOverlay,
              EBranchMutationType.ResizeAndConcat,
              EBranchMutationType.AddWatermark,
              EBranchMutationType.AddBackgroundMusic,
            ].map((e) => {
              if (availableMutations.includes(e)) {
                return (
                  <option value={e} key={e}>
                    {branchMutationTranslate(e)}
                  </option>
                );
              }
            })}
          </select>
        </div>

        {typeSelected === EBranchMutationType.AddDynamicOverlay ? (
          <AddDynamicOverlay
            mediaSequenceId={mediaSequence.id}
            dynamicTemplateId={
              targetBranchMutation?.addDynamicOverlay?.dynamicTemplateId
            }
            previewAspectRatio={
              mediaSequence.branches[0].width / mediaSequence.branches[0].height
            }
            inDialog={inDialog}
            fallBackdynamicTemplateFieldValues={
              disabled
                ? targetBranchMutation?.addDynamicOverlay
                    ?.dynamicTemplateFieldValues
                : undefined
            }
            setFieldIdsOfCurrentDynamicTemplate={
              setFieldIdsOfCurrentDynamicTemplate
            }
          />
        ) : null}

        {typeSelected === EBranchMutationType.ResizeAndConcat ? (
          <ResizeAndContact mediaSequence={mediaSequence} />
        ) : null}

        {typeSelected === EBranchMutationType.AddWatermark ? (
          <AddWatermark
            targetBranchMutation={targetBranchMutation}
            projectId={mediaSequence._case?.project.id}
            inDialog={inDialog}
          />
        ) : null}

        <div className="flex justify-center space-x-4 pt-4">
          <button
            className="btn btn-primary"
            disabled={isPendingCreate || isPendingUpdate}
          >
            {targetBranchMutation ? 'Opdater gemt effekt' : 'Gem effekt'}
          </button>
          {onCancel ? (
            <button type="button" className="btn" onClick={onCancel}>
              Annuller
            </button>
          ) : null}
        </div>
      </form>
    </FormProvider>
  );
};
