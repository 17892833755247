import {
  EAssetMutationType,
  EMediaSequenceType,
  MediaSequenceAssetResponse,
  MediaSequenceResponse,
} from 'api/core';

export const isTemplate = (
  mediaSequence: MediaSequenceResponse | EMediaSequenceType
) => {
  const type =
    typeof mediaSequence === 'string' ? mediaSequence : mediaSequence.type;
  return (
    type === EMediaSequenceType.Template ||
    type === EMediaSequenceType.StrictTemplate
  );
};

export const MEDIA_SEQUENCE_ACCEPTED_FILES = 'image/*,video/*,application/pdf';

export const getMediaSequenceBranchDynamicOverlayMutations = (
  mediaSequence: MediaSequenceResponse
) => {
  const addDynamicOverlayBranchMutations =
    mediaSequence?.branchMutations.filter(
      (e) => e.type === EAssetMutationType.AddDynamicOverlay
    );

  return addDynamicOverlayBranchMutations;
};

export const getMediaSequenceAssetDynamicOverlayMutations = (
  assets: MediaSequenceAssetResponse[]
) => {
  const addDynamicOverlayAssetMutations = assets.flatMap((asset) => {
    return asset.mutations.filter(
      (e) => e.type === EAssetMutationType.AddDynamicOverlay
    );
  });

  return addDynamicOverlayAssetMutations;
};
