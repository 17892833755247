import { useTableQueryState } from 'components/Table/useTableQueryState';
import { Page } from 'layouts/Admin/Page';
import { useAuth } from 'auth/AuthProvider';
import { useGetTickets } from 'api/useServiceTicketApi';
import { ServiceTicketsTable } from './ServiceTicketsTable';
import { ServiceTicketCreate } from './ServiceTicketCreate';
import { PermissionProtectedComponent } from 'auth/PermissionProtectedComponent';
import { EPermission } from 'api/core';
import { useState } from 'react';

export const ServiceTickets = () => {
  const [showArchived, setShowArchived] = useState(false);
  const { user } = useAuth();

  return (
    <Page
      title={user?.organization.name}
      breadcrumbs={[
        { name: user?.organization.name ?? '' },
        { name: 'Support' },
      ]}
    >
      <div className="flex justify-start py-2 pb-3 w-full">
        <PermissionProtectedComponent
          permissions={[EPermission.ServiceTicketWrite]}
        >
          <ServiceTicketCreate
            trigger={
              <button className="btn btn-primary">Opret support ticket</button>
            }
          />
        </PermissionProtectedComponent>
        <div className="ml-auto">
          <label className="cursor-pointer label">
            <span className="label-text me-2">Vis Færdige</span>
            <input
              type="checkbox"
              id="showArchived"
              name="showArchived"
              checked={showArchived}
              onChange={() => setShowArchived(!showArchived)}
              className="toggle toggle-primary"
            />
          </label>
        </div>
      </div>
      <TableWrapper
        includeActive={!showArchived}
        includeArchived={showArchived}
      />
    </Page>
  );
};

interface TableWrapperProps {
  includeActive: boolean;
  includeArchived: boolean;
}

const TableWrapper = ({
  includeActive,
  includeArchived,
}: TableWrapperProps) => {
  const { state } = useTableQueryState();
  if (state.sorting.length === 0) {
    state.setSorting([{ id: 'lastModifiedUtc', desc: true }]);
  }

  const expectedFilter = JSON.stringify({
    includeActive: includeActive,
    includeArchived: includeArchived,
  });

  if (state.customState != expectedFilter) {
    state.setCustomState(expectedFilter);
  }

  const { data: tickets, isFetching: isFetching } = useGetTickets(false, state);

  return (
    <ServiceTicketsTable
      data={tickets}
      isFetching={isFetching}
      queryState={state}
      showOrganizationColumn={false}
      showSupportersColumn={false}
    />
  );
};
